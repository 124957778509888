/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import ContentCallToAction from '~/components/Content/Section/CallToAction'

const TextSection = ({ title, description, children, cta }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['100%'],
        margin: 'auto',
        marginTop: ['24px', '24px', '96px'],
        marginBottom: ['24px', '24px', '96px']
      }}
    >
      <Heading
        as="h2"
        sx={{
          textAlign: 'center',
          color: 'primary',
          variant: ['text.h3', 'text.h3', 'text.h2'],
          marginTop: ['16px', '16px', '36px'],
          marginBottom: ['16px', null, '32px']
        }}
      >
        {title}
      </Heading>
      {description && (
        <Box
          sx={{
            textAlign: 'center',
            '& p': {
              maxWidth: ['100%', null, '70%'],
              margin: 'auto',
              marginBottom: ['24px', null, '48px']
            },
            a: {
              variant: 'text.link'
            }
          }}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html
          }}
        />
      )}
      {children}

      {cta && (
        <ContentCallToAction
          sx={{
            textAlign: 'center',
            marginBottom: ['24px', null, '48px']
          }}
          content={cta.content}
        />
      )}
    </Flex>
  )
}

TextSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  collections: PropTypes.arrayOf(PropTypes.shape({})),
  cta: PropTypes.shape({}),
  children: PropTypes.shape({})
}

export default TextSection
