/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, Box, Heading, jsx, Text } from 'theme-ui'
import ProductList from '~/components/Product/List'
import ContentCallToAction from '~/components/Content/Section/CallToAction'

const ProductSection = ({ title, description, products, cta }) => {
  return (
    <Flex
      sx={{
        background: '#e5e1de',
        flexDirection: 'column',
        padding: '1rem',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          maxWidth: 'calc(1200px + 3rem)',
          // width: ['100%', '80%'],
          margin: ['0 auto', '36px auto 0']
        }}
      >
      <Heading
        as="h1"
        variant="h1"
        sx={{
          fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
          fontWeight: 'normal',
          fontSize: ['34px', '44px', '55px'],
          textAlign: 'center',
          color: 'primary',
          marginTop: [null, null, '36px'],
          marginBottom: ['8px', null, '32px']
        }}
      >
        {title}
      </Heading>
      {description && (
        <Text
          sx={{
            variant: 'text.body',
            color: 'primary',
            textAlign: 'center'
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html
            }}
          />
        </Text>
      )}
      <Box sx={{ margin: ['2rem 0', '2rem']}}>
        <ProductList products={products} />
      </Box>
      {cta && (
        <Box
          sx={{
            width: ['100%', '100%', '80%'],
            span: {
              variant: 'text.link'
            },
            margin: 'auto',
            marginBottom: ['8px', '8px', '48px']
          }}
        >
          <ContentCallToAction
            sx={{
              display: 'inline-block',
              marginBottom: ['32px', '32px', '0']
            }}
            content={cta.content}
          />
        </Box>
      )}
      </Box>
    </Flex>
  )
}

ProductSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  cta: PropTypes.shape({})
}

export default ProductSection
