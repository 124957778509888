/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Flex, Grid, Heading, jsx, Text } from 'theme-ui'
import CollectionCard from '~/components/Collection/Card'

const CollectionSection = ({ title, description, collections }) => {
  return (
    <Flex sx={{ flexDirection: 'column', background: '#e5e1de', }}>
      <Box
        sx={{
          maxWidth: 'calc(1200px + 3rem)',
          //width: ['100%', '80%', '60%'],
          margin: '36px auto 0',
          padding: ['0 1.5rem', 0],
        }}
      >
        <Heading
          as="h1"
          variant="h1"
          sx={{
            fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
            fontWeight: 'normal',
            fontSize: ['34px', '44px', '55px'],
            textAlign: 'center',
            color: 'primary',
            marginTop: ['16px', null, '36px'],
            marginBottom: ['8px', null, '32px']
          }}
        >
          {title}
        </Heading>
        {description && (
          <Text
            sx={{
              variant: 'text.link',
              color: 'primary',
              textAlign: 'center'
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          </Text>
        )}
        <Grid
          columns={[1, 3]}
          gap={['12px', '1rem']}
          p={['0 0 2rem', '0 0.5rem 0']}
          sx={{
            marginTop: '36px',
            marginBottom: '36px'
          }}
        >
          {collections.map(collection => (
            <CollectionCard key={collection.slug} collection={collection} />
          ))}
        </Grid>
      </Box>
    </Flex>
  )
}

CollectionSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string
  }),
  collections: PropTypes.arrayOf(PropTypes.shape({}))
}

export default CollectionSection
