/** @jsx jsx */
import PropTypes from 'prop-types'
import {
  Button,
  Box,
  Container,
  Flex,
  Heading,
  Text,
  jsx
} from 'theme-ui'
import Img from 'gatsby-image'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const ImageHeroWithGradient = ({ heading, image, subheading }) => {
  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          position: 'relative'
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Img
            fluid={image.fluid}
            sx={{
              height: '100%',
              py: ['2px'],
              minHeight: '108px',
              maxHeight: ['240px', '280px', '450px']
            }}
          />
        </Box>
        <Flex
          sx={{
            width: ['100%', '70%', '65%'],
            textAlign: ['center', 'left', 'left'],
            flexDirection: 'column',
            justifyContent: 'center',
            padding: ['1rem', '1rem', '4rem'],
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: [
              'rgba(0,0,0,.3)',
              'linear-gradient(to right,rgba(0,0,0,.2), rgba(0,0,0,.2) 60%, rgba(0,0,0,0))',
              null
            ]
          }}
        >
          <Heading as="h1" variant="h1" sx={{ color: 'background' }}>
            {heading.heading}
          </Heading>
          {subheading && (
            <Heading
              as="h3"
              variant="subhead"
              sx={{ marginTop: '1rem', color: 'background' }}
            >
              {subheading.subheading}
            </Heading>
          )}
        </Flex>
      </Flex>
    </Container>
  )
}

const ImageHeroWithoutGradient = ({ heading, image, subheading }) => {
  const translate = useTranslate()

  return (
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          padding: ['2rem 0', '4rem 0'],
        }}
      >
      <Flex
        sx={{
          maxWidth: '1200px',
          marginBottom: ['16px', null, '16px'],
          flexDirection: ['column', 'row-reverse'],
          width: '100%',
        }}
      >
        <Heading
          sx={{
            color: 'rgb(223, 227, 61)',
            display: ['flex', 'none'],
            fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
            fontSize: '50px',
            fontWeight: 'normal',
            lineHeight: '54px',
            marginBottom: '1rem',
          }}
          as="h1" variant="h1">
          {heading.heading}
        </Heading>
        <form action="/shop">
            <Button sx={{
              backgroundColor: 'rgb(223, 227, 61)',
              '&:hover': {
                backgroundColor: 'rgb(223, 227, 61)',
                filter: 'brightness(0.9)'
              },
              borderRadius: '0.25rem',
              color: 'rgb(0, 93, 95)',
              display: ['block', 'none'],
              transition: 'filter 0.15s ease-in-out 0s',
              fontSize: ['13px', null, '14px'],
              fontFamily: 'DIN Alternate Bold,Helvetica,Arial,Sans-Serif',
              letterSpacing: 'normal',
              padding: '0.75rem 0.5rem',
              marginTop: '2rem',
              marginBottom: '2rem',
              width: ['100%', '80%'],
            }}>
              <Text>{ translate('home.hero.cta') }</Text>
            </Button>
         </form>
        <Flex sx={{
          paddingRight: [0, '2rem', '3rem'],
          paddingLeft: [0, '2rem', '3rem'],
          marginTop: [0, 0, '-3rem'],
          width: ['100%', '60%']
        }}>
          <Img
            fluid={image.fluid}
            sx={{
              height: [null, '450px', '100%'],
              width: '100%',
            }}
          />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '40%'],
            padding: ['0', '5rem 2rem', '0'],
            justifyContent: 'start'
          }}
        >
          <Heading
            sx={{
              color: 'rgb(223, 227, 61)',
              display: ['none', 'flex'],
              fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
              fontSize: '62px',
              fontWeight: 'normal',
              lineHeight: '65px',
            }}
            as="h1" variant="h1">
            {heading.heading}
          </Heading>
          {subheading && (
            <Heading
              as="h3"
              variant="subhead"
              sx={{
                fontSize: '16px',
                lineHeight: '25px',
                marginTop: ['2rem', '3rem'],
                color: 'background'
              }}
            >
              {subheading.subheading}
            </Heading>
          )}
          <form action="/shop">
            <Button sx={{
              backgroundColor: 'rgb(223, 227, 61)',
              '&:hover': {
                backgroundColor: 'rgb(223, 227, 61)',
                filter: 'brightness(0.9)'
              },
              borderRadius: '0.25rem',
              color: 'rgb(0, 93, 95)',
              display: ['none', 'block'],
              transition: 'filter 0.15s ease-in-out 0s',
              fontSize: ['13px', null, '14px'],
              fontFamily: 'DIN Alternate Bold,Helvetica,Arial,Sans-Serif',
              letterSpacing: 'normal',
              padding: '0.75rem 0.5rem',
              marginTop: '4rem',
              width: ['100%', '80%'],
            }}>
              <Text>{ translate('home.hero.cta') }</Text>
            </Button>
          </form>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const ImageHero = ({ gradient = false, ...props }) => {
  return gradient ? (
    <ImageHeroWithGradient {...props} />
  ) : (
    <ImageHeroWithoutGradient {...props} />
  )
}

ImageHero.propTypes = {
  heading: PropTypes.shape({
    heading: PropTypes.string
  }).isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.shape()
  }).isRequired,
  subheading: PropTypes.shape({
    subheading: PropTypes.string
  }),
  gradient: PropTypes.bool
}

export default ImageHero
