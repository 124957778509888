/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Flex, jsx } from 'theme-ui'
import ArticleSection from './Article'
import CollectionSection from './Collection'
import ContentCardSection from './ContentCard'
import HeroSection from './Hero'
import ProductSection from './Product'
import QuoteSection from './Quote'
import TextSection from './Text'

const mediaType = media => {
  return media.__typename
}

const ContentSection = ({ section }) => {
  return (
    <Flex
      key={section.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      {section.media && section.media.length > 0 && mediaType(section.media[0]) === 'ContentfulArticle' && (
        <ArticleSection
          title={section.title}
          description={section.description}
          articles={section.media}
        />
      )}
      {section.media && section.media.length > 0 ? (
        <div>
          {section.media.map((media, index) => (
            <Box
              key={media.id}
              sx={{
                margin: '0 auto',
                maxWidth: 'calc(1200px + 3rem)',
                padding: '0 1.5rem',
            }}>
              {mediaType(media) === 'ContentfulHero' && (
                <HeroSection hero={media} textColor={'rgb(81,39,115)'} />
              )}
              {mediaType(media) === 'ContentfulProduct' && (
                <ProductSection
                  products={section.media}
                  title={section.title}
                  description={section.description}
                  cta={section.cta}
                />
              )}

              {mediaType(media) === 'ContentfulCollection' && (
                <CollectionSection
                  collections={section.media}
                  title={section.title}
                  description={section.description}
                />
              )}

              {mediaType(media) === 'ContentfulQuote' && (
                <QuoteSection quotes={section.media} />
              )}

              {mediaType(media) === 'ContentfulContentCard' && (
                <ContentCardSection contentCards={media} index={index} />
              )}
            </Box>
          ))}
        </div>
      ) : (
        <TextSection
          title={section.title}
          description={section.description}
          cta={section.cta}
        />
      )}
    </Flex>
  )
}

ContentSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({
      description: PropTypes.string
    }),
    media: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

export default ContentSection
