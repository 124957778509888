/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import ContentHero from '~/components/Content/Hero'
import ContentSection from '~/components/Content/Section'
import { Box } from 'theme-ui'

const ContentPage = ({ backgroundColor, page }) => {
  return (
    <Box sx={{
      backgroundColor: backgroundColor
    }}>
      {page.hero && <ContentHero hero={page.hero} />}
      {page.sections &&
        page.sections.map(section => {
          return <ContentSection key={section.id} section={section} />
        })}
    </Box>
  )
}

ContentPage.propTypes = {
  backgroundColor: PropTypes.string,
  page: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
}

export default ContentPage
